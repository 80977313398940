export enum RoutePattern {
  HOME = "home",
  HERO = "hero",
  MUSIC = "music",
  VIDEOS = "videos",
  ABOUT = "ABOUT",
  BIO = "bio",
  MERCH = "MERCH",
  TOUR = "tour",
  STREAMING = "streaming",
  PRESS = "press",
  GALLERY = "gallery",
  SUBSCRIBE = "subscribe",
}
