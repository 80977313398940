import { useEffect } from 'react';

interface ScriptProps {
  url?: string;
  innerHTML?: string;
}

const useScriptService = ({ url, innerHTML }: ScriptProps) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (url) {
      script.src = url;
    }

    if (innerHTML) {
      script.innerHTML = innerHTML;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, innerHTML]);
};

export default useScriptService;
