import { IframePlaylist } from "../../components";
import { VideoIframe } from "../../components/IframePlaylist/types";
import MusicSlider from "../../components/MusicSlider/MusicSlider";
import { MusicSliderDataTypes } from "../../components/MusicSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/Dlow tape cover solo img 400.png";

const data: MusicSliderDataTypes[] = [
  {
    title: "As Above, So Below",
    img: "https://cdn.campaigns.wmgconnect.com/uploads/hsaasb.jpg",
    link: "https://highlysuspect.lnk.to/asabovesobelow",
    btnTxt: "Listen Now",
  },
  {
    title: "Summertime Voodoo",
    img: "https://cdn.campaigns.wmgconnect.com/uploads/hsaasb.jpg",
    link: "https://highlysuspect.lnk.to/summertimevoodoo",
    btnTxt: "Listen Now",
  },
  {
    title: "The Midnight Demon Club",
    img: "https://cloudinary-cdn.ffm.to/s--hbUbkZU8--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F4ee2c4ccefc4dfd93f435398701de0cb.jpeg",
    link: "https://highlysuspect.ffm.to/mdc_",
    btnTxt: "Listen Now",
  },
  {
    title: "Natural Born Killer",
    img: "https://cloudinary-cdn.ffm.to/s--z55EgCgF--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2Ff83f94911df329df6ddd0c1469c23515.jpg",
    link: "https://highlysuspect.ffm.to/nbk_",
    btnTxt: "Listen Now",
  },
];

const Music = () => {
  return (
    <div
      id={RoutePattern.MUSIC}
      className="font-secondary w-full py-[14vh] md:px-[10vw] z-20 flex flex-col justify-center items-center"
    >
      <h2 className="text-center leading-[100%] text-[10vh] mb-[24px] px-2 md:px-5 uppercase tracking-[2px]">
        Music
      </h2>
      <div className="w-full flex justify-center items-center px-2 md:px-5">
        {/* <p>Coming Soon</p> */}
        <div className="w-full">
          <MusicSlider data={data} />
        </div>
      </div>
    </div>
  );
};

export default Music;
