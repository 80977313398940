import styled from "styled-components";
import { ArrowTypes } from "./types";

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  z-index: 2;
  top: 110%;
  right: ${(props) => props.right && "35%"};
  left: ${(props) => props.left && "35%"};
  transform: translateY(-30%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    top: 50%;
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
  }
`;
