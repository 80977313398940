import styled from "styled-components";
import { RoutePattern } from "../../routes/RoutePattern";
import { motion } from "framer-motion";
import IMG_1 from "../../assets/images/dlow-tour-flier-3x4.png";
import useScriptService from "../../hooks/useScriptService";

export const TourImage = styled(motion.a)`
  transition: all 0.3s ease;
  overflow: hidden;
  text-align: right;
  width: 30vw;

  /*:hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }*/

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const Tour = () => {
  // useScriptService({ url: "https://widgetv3.bandsintown.com/main.min.js" });
  useScriptService({ url: "https://widget.seated.com/app.js" });

  return (
    <div
      id={RoutePattern.TOUR}
      className="font-secondary w-full pt-[14vh] md:px-[10vw] z-20 flex flex-col justify-center items-center"
    >
      <h2 className="text-center leading-[100%] text-[10vh] mb-[24px] px-2 md:px-5 uppercase tracking-[2px]">
        Tour
      </h2>
      <div className="flex flex-col justify-center items-center w-full px-2 md:px-5">
        {/* <p>Coming Soon</p> */}
        {/* <div className="w-full">
          <MusicSlider data={data} />
        </div> */}
        {/* <TourImage>
          <img
            src={IMG_1}
            alt="ffm image"
            className="transition-all w-full h-full object-contain"
          />
        </TourImage> */}
        <div className="w-full mt-[4vh]">
          {/* <a
            className="bit-widget-initializer"
            data-artist-name="ID_1222173"
            data-display-local-dates="false"
            data-display-past-dates="false"
            data-auto-style="false"
            data-text-color="#fff"
            data-link-color="#fff"
            data-background-color="rgba(0,0,0,0)"
            data-display-limit="15"
            data-display-start-time="false"
            data-link-text-color="#FFFFFF"
            data-display-lineup="false"
            data-display-play-my-city="false"
            data-separator-color="rgba(124,124,124,0.25)"
          ></a> */}
          <div
            id="seated-55fdf2c0"
            data-artist-id="98531715-6c4d-4880-adc5-e7c48e614d58"
            data-css-version="3"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Tour;
