import SocialIcons from "../SocialIcons/SocialIcons";

export default function Footer() {
  // footer
  return (
    <div id="footer" className="font-secondary text-[2vh] w-full z-50">
      <div className="bg-none flex flex-col justify-center items-center text-center p-5">
        <span className="uppercase text-center">&copy; Highly Suspect</span>
        <SocialIcons />
        {/* <div>
          <a href="#" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
        </div> */}
      </div>
    </div>
  );
}
