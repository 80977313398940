export const iOS = (() => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
})();

export const CriOS = (() => {
  return (
    /CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent)
  );
})();
