import { IframePlaylist } from "../../components";
import { VideoIframe } from "../../components/IframePlaylist/types";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import { RoutePattern } from "../../routes/RoutePattern";

const data: VideoIframe[] = [
  {
    title: "Highly Suspect - Summertime Voodoo [Official Lyric Video]",
    videoId: "UP8irm3M9Xc",
  },
  // {
  //   title: "Highly Suspect - New California [Official Video]",
  //   videoId: "-HlYWdfY020",
  // },
  {
    title: "Highly Suspect - Natural Born Killer [Official Video]",
    videoId: "WMcV7MNbwX4",
  },
  {
    title: "Highly Suspect - These Days (Official Acoustic Video)",
    videoId: "zMx7aK7dX04",
  },
  {
    title: "Highly Suspect - These Days [Official Video]",
    videoId: "r1gqt96Cq4c",
  },
  {
    title: "Highly Suspect - 16 [Official Music Video]",
    videoId: "ABsZL29jzJE",
  },
];

const Videos = () => {
  return (
    <div
      id={RoutePattern.VIDEOS}
      className="font-secondary w-full py-[14vh] md:px-[10vw] z-20 flex flex-col justify-center items-center"
    >
      <h2 className="text-center leading-[100%] text-[10vh] mb-[24px] px-2 md:px-5 uppercase tracking-[2px]">
        Videos
      </h2>
      <div className="w-full flex justify-center items-center px-2 md:px-5">
        <div className="w-full">
          <YoutubeSlider
            data={data}
            // playlistId="PLh8Wm_EGriA0LAhTHOAOVWOivUV764ILK"
          />
        </div>
      </div>
    </div>
  );
};

export default Videos;
