import { AnyAction, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { rootReducer } from "./features";

const Store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const dispatch = (action: AnyAction) => {
  Store.dispatch(action);
};

export type RootState = ReturnType<typeof Store.getState>;
export default Store;
