import {
  faAmazon,
  faApple,
  faFacebookF,
  faInstagram,
  faSnapchat,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/2pqd3HsfsvcBGtHvPOg6eg?si=p_mVkJ_LRJK9xf_NF8aYLg",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/highly-suspect/419913603",
  },
  {
    icon: faTwitter,
    link: "https://twitter.com/Highly_Suspect",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/highlysuspect/?hl=en",
  },
  {
    icon: faFacebookF,
    link: "https://www.facebook.com/HighlySuspect",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/@HighlySuspect",
  },
  {
    icon: faTiktok,
    link: "https://www.tiktok.com/@highlysuspect.mcid",
  },
];
