import styled from "styled-components";
import { ArrowTypes } from "./types";

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  z-index: 2;
  transform: translateY(-50%);
  top: 110%;
  right: ${(props) => (props.right ? "38%" : "inherit")};
  left: ${(props) => (props.left ? "38%" : "inherit")};
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 980px) {
    top: 35%;
    right: ${(props) => (props.right ? props.right : "inherit")};
    left: ${(props) => (props.left ? props.left : "inherit")};
  }
`;
