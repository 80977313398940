import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Arrow, SlideItem } from "./styled.components";
import { MusicDetail, MusicImage } from "./styled.components";
import { MusicSliderDataTypes } from "./types";

const Container = styled(motion.div)``;

interface MerchSliderProps {
  data: MusicSliderDataTypes[];
}

export default function MusicSlider({ data }: MerchSliderProps) {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  const ArrowPrev = (props: any) => {
    const { onClick } = props;
    return (
      <Arrow type="button" left="10%" className="from-lef" onClick={onClick}>
        <FontAwesomeIcon icon={faAngleLeft as any} />
      </Arrow>
    );
  };

  const ArrowNext = (props: any) => {
    const { onClick } = props;

    return (
      <Arrow type="button" onClick={onClick} right="10%" className="from-righ">
        <FontAwesomeIcon icon={faAngleRight as any} />
      </Arrow>
    );
  };

  const items = data.map((item, idx) => {
    return (
      <SlideItem
        key={idx}
        className="text-white my-auto text-center overflow-visible mx-auto w-3/4"
      >
        <MusicImage href={item.link} rel="noreferrer" target="_blank">
          <img
            src={item.img}
            alt="ffm image"
            className="transition-all w-full h-full object-contain"
          />
        </MusicImage>
        <MusicDetail className="mt-[4vh] text-[4vh] md:text-[1.4vw]">
          <h2 className="font-bold italic">{item.title}</h2>
          <a
            href={item.link}
            target="_blank"
            rel="noreferrer"
            className="uppercase font-calibre-medium underline"
          >
            {item.btnTxt}
          </a>
        </MusicDetail>
      </SlideItem>
    );
  });

  return (
    <Container className="overflow-visible overscroll-none">
      <Slider
        dots={false}
        nextArrow={<ArrowNext />}
        prevArrow={<ArrowPrev />}
        slidesPerRow={1}
        slidesToShow={1}
      >
        {items}
      </Slider>
    </Container>
  );
}
